import React from 'react';

// Components
import { MarkdownTemplate } from 'templates';

// Layouts
import { SEOContent } from 'layouts';

// Hooks
import { useAboutPageSectionsQuery } from 'hooks';

const title = 'About MSTCA';

const AboutPageTemplate = () => {
  const { sections } = useAboutPageSectionsQuery();

  return (
    <SEOContent>
      <MarkdownTemplate postTitle={title} sections={sections} />
    </SEOContent>
  );
};

export default AboutPageTemplate;
